import React from 'react'
import debug from 'debug'
import { Button, ButtonProps, Icon, Popup } from 'semantic-ui-react'
import { selectors as refreshSelectors } from 'AppSrc/refresh/reducer'
import { metaDataRequest, selectors as metaDataSelectors } from './reducer'
import { MetaDataPayloadDataType } from './types'
import { getMetaDataIdx } from './helpers'
import { useAppDispatch, useAppSelector } from 'AppSrc/store'
import './style.css'
import { nameCapitalize } from 'AppSrc/components/registrants/helpers'

debug.enable('metadataeligibleforauditiontrack/component:*')
const log = debug('metadataeligibleforauditiontrack/component:log')
// const info = debug('metadataeligibleforauditiontrack/component:info')
// const error = debug('metadataeligibleforauditiontrack/component:error')

type Props = { regId: string }

const metaDataType = 'eligible-for-audition-track'

const MetaDataEligibleForAuditionTrack = ({ regId }: Props) => {
  const dispatch = useAppDispatch()

  const metaDataStatus = useAppSelector(state => metaDataSelectors.metaDataStatus(state))
  // const metaDataDate = useAppSelector(state => metaDataSelectors.metaDataDate(state)),
  // const errorData = useAppSelector(state => metaDataSelectors.errorData(state)),
  const users: DbUsersType = useAppSelector(state => refreshSelectors.users(state))

  // log('userId', userId)
  if (!regId || regId === '' || regId === '0' || isNaN(Number(regId)) || !users[regId]) {
    // no corresponding user or registering a new user
    return null
  }

  const user = users[regId]
  // log('user', user)

  const onClickFunc = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: ButtonProps
  ) => {
    const idx = getMetaDataIdx(regId, metaDataType)
    const payloadData: MetaDataPayloadDataType = {
      user: {
        userId: Number(regId),
        // If already set, this is a request to unset it.
        meta: {
          eligible_for_audition_track: !(user.meta.eligible_for_audition_track ?? false),
        },
      },
    }

    log('metaDataRequest', idx, payloadData)
    dispatch(metaDataRequest(idx, payloadData))
  }

  const components = []
  const metaData = user.meta

  const idx = getMetaDataIdx(regId, metaDataType)
  const metaDataUpdateStatus = metaDataStatus[idx]
  const loading = !!(metaDataUpdateStatus || '').match(/ing/) // FIXME /ing/

  const action = metaData.eligible_for_audition_track ? 'cancel' : 'add'
  const color = metaData.eligible_for_audition_track ? 'orange' : 'grey'

  components.push(
    <Popup
      key="eligible-for-audition-track"
      hoverable
      flowing
      on={['click']}
      trigger={
        <Icon
          name="paw"
          color={color}
          fitted
          loading={loading}
          className="icon-button eligible-for-audition-track-icon"
        />
      }
      header="Eligible for Audition Track"
      content={
        <div>
          <div>{`Do you want to ${action} eligibility?`}</div>
          <div style={{ textAlign: 'center', marginTop: '.5em' }}>
            <Button
              size="large"
              onClick={onClickFunc}
              color="red"
              basic
              content={`Yes, ${nameCapitalize(action)}`}
            />
          </div>
        </div>
      }
      offset={[-12, 0]}
      className="eligible-for-audition-track-popup"
    />
  )

  return <div className="metadata">{components}</div>
}

export default MetaDataEligibleForAuditionTrack
